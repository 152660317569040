import gql from 'graphql-tag';

export const LOGIN = gql`
mutation authUser($email_or_phone: String!, $password: String!) {
	authUser (email_or_phone: $email_or_phone, password: $password) {
		token
	}
}
`;
export const MAKE_DOCTOR = gql`
mutation makeDoctor($email: String!) {
	makeDoctor (email: $email) {
		email
		isDoctor
	}
}
`;
export const MAKE_ADMIN = gql`
mutation makeAdmin($email: String!) {
	makeAdmin (email: $email) {
		email
		isAdmin
	}
}
`;
export const DELETE_USER = gql`
mutation deleteUser($email: String!) {
	deleteUser (email: $email) {
		email
		isAdmin
	}
}
`;
export const ADD_MEMBERSHIP = gql`
mutation createSubscriptionPlan($email: String!, $duration: String!) {
	createSubscriptionPlan (email: $email, duration: $duration) {
		email
		duration
	}
}
`;


export const REGISTER = gql`
mutation registerUser($email_or_phone: String!, $password: String!, $isDoctor: Boolean!) {
	registerUser (email_or_phone: $email_or_phone, password: $password, isDoctor: $isDoctor) {
		token
	}
}
`;