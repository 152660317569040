import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { BsHouse, BsPeople, BsBoxArrowInRight, BsBoxArrowRight } from 'react-icons/bs';

 import { AuthContext } from '../../AuthContext';

const SIZE = '32px';
export const TEXT_COLOR = "#Bfd101"

export const NavBar = () => {
	const { isAuth } = useContext(AuthContext);
	const { userData } = useContext(AuthContext);

	return (
		<nav className="navbar navbar-expand-lg justify-content-between d-flex mt-2 mb-5" style={{borderRadius:"10px", borderColor:TEXT_COLOR}}>
			<Link className="navbar-item font-weight-bold" style={{color:TEXT_COLOR}} to='/'>
				<BsHouse size={SIZE} title='Home'/>
			</Link>
			{ 
				isAuth && userData.isAdmin && <Link className="navbar-item font-weight-bold" style={{color:TEXT_COLOR}} to='/user-administration'>
					<BsPeople size={SIZE} title='User administration'/>
				</Link>
			}
			<Link className="navbar-item font-weight-bold" style={{color: TEXT_COLOR}} to='/logout'>
				{ !isAuth && <BsBoxArrowInRight size={SIZE} title='Login'/> }
				{ isAuth && <BsBoxArrowRight size={SIZE} title='Logout'/> }
			</Link>
		</nav>
	);
};