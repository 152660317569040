import PropTypes from 'prop-types';
import { TEXT_COLOR } from '../NavBar';

export const SubmitButton = ( { children, disabled, onClick } ) => {
	return <button disabled={disabled} className="btn btn-outline" style={{color:TEXT_COLOR, borderColor:TEXT_COLOR}} onClick={onClick}>{children}</button>;
};

SubmitButton.propTypes = {
	children: PropTypes.node.isRequired,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
};