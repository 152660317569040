export const Footer = () => {
	return (
		<footer className="footer mt-auto py-3 fixed-bottom border-top border-info">
			<div className="container text-center">
				<span className="text-muted font-weight-light" style={{color:"#bfd101"}}>
					Made by <a className="text-info" target="_blank" rel="noreferrer noopener" href="">smileDom</a>
				</span>
			</div>
		</footer>
	);
};
